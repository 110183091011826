
import { useEffect } from "react";
import Navigation from "@/components/Navigation";
import Hero2 from "@/components/Hero2";
import AboutBookSection from "@/components/AboutBookSection";
import TestimonialsSection from "@/components/TestimonialsSection";
import NewsletterSection from "@/components/NewsletterSection";
import Footer from "@/components/Footer";
import BookLesson from "@/components/BookLesson";
import BookAudience from "@/components/BookAudience";
import AuthorSection from "@/components/AuthorSection";
import SpeakingSection from "@/components/SpeakingSection";

const Home2 = () => {
  useEffect(() => {
    document.title = "Sharks, Snakes & Business Partners | Cody McLain";
  }, []);

  return (
    <div className="min-h-screen bg-background">
      <Navigation />
      <Hero2 />
      <AboutBookSection />
      <BookLesson />
      <BookAudience />
      <AuthorSection />
      <TestimonialsSection />
      <SpeakingSection />
      <NewsletterSection />
      <Footer />
    </div>
  );
};

export default Home2;
