
import { Star, MessageCircle } from "lucide-react";

const TestimonialsSection = () => {
  // Using placeholder testimonials until real ones are available
  const testimonials = [
    {
      quote: "[Testimonial placeholder] This book saved me from making a costly mistake with the wrong business partner. The red flag checklist alone is worth 10x the price.",
      author: "Future Reader",
      title: "Entrepreneur",
      stars: 5
    },
    {
      quote: "[Testimonial placeholder] A must-read for anyone considering a business partnership. I wish I had this knowledge before my first venture.",
      author: "Future Reader",
      title: "Business Owner",
      stars: 5
    },
    {
      quote: "[Testimonial placeholder] Finally, practical advice that doesn't just tell you to 'trust your gut.' The frameworks in this book are game-changers.",
      author: "Future Reader",
      title: "Investor",
      stars: 5
    }
  ];

  return (
    <section className="py-24 bg-white">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16 max-w-3xl mx-auto">
          <span className="inline-block px-4 py-1.5 mb-6 text-sm font-medium bg-purple-100 text-purple-800 rounded-full">
            Testimonials
          </span>
          <h2 className="text-4xl font-avenir font-bold mb-6">
            Reader Testimonials
          </h2>
          <p className="text-gray-700">
            <MessageCircle className="inline-block h-5 w-5 mr-2 text-purple-500" />
            Testimonials will be added once available
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 opacity-50">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="bg-gray-50 p-8 rounded-xl">
              <div className="flex mb-4">
                {Array.from({ length: testimonial.stars }).map((_, i) => (
                  <Star key={i} className="h-5 w-5 text-yellow-400 fill-current" />
                ))}
              </div>
              
              <p className="text-gray-700 mb-6 italic">{testimonial.quote}</p>
              
              <div>
                <p className="font-bold">{testimonial.author}</p>
                <p className="text-gray-500 text-sm">{testimonial.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
