
import { Mic, Mail } from "lucide-react";

const SpeakingSection = () => {
  return (
    <section className="py-24 bg-gray-50">
      <div className="container mx-auto px-6">
        <div className="max-w-3xl mx-auto text-center">
          <div className="inline-flex justify-center items-center mb-6 bg-purple-100 p-3 rounded-full">
            <Mic className="h-6 w-6 text-purple-500" />
          </div>
          <h2 className="text-4xl font-avenir font-bold mb-6">
            Book Cody for Speaking
          </h2>
          <p className="text-xl text-gray-700 mb-8">
            Interested in having Cody speak about partnership dynamics at your next event?
          </p>
          
          <div className="bg-white p-8 rounded-xl shadow-sm">
            <div className="flex items-center justify-center mb-6">
              <Mail className="h-5 w-5 text-purple-500 mr-2" />
              <h3 className="text-lg font-medium">Contact for availability and topics</h3>
            </div>
            
            <button 
              className="px-8 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
              onClick={() => window.location.href = "mailto:contact@codymclain.com"}
            >
              Email for Speaking Inquiries
            </button>
            
            <div className="mt-6 text-gray-500 text-sm">
              Please include event details, dates, and audience information in your inquiry.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SpeakingSection;
