
import { Check } from "lucide-react";

const AboutBookSection = () => {
  return (
    <section className="py-24 bg-white">
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row gap-12">
          <div className="md:w-1/2">
            <span className="inline-block px-4 py-1.5 mb-6 text-sm font-medium bg-amber-100 text-amber-800 rounded-full">
              About The Book
            </span>
            <h2 className="text-4xl font-avenir font-bold mb-6">
              Navigate Business Relationships With Confidence
            </h2>
            <p className="text-gray-700 mb-6">
              In the wild world of business partnerships, not all sharks swim in the ocean.
            </p>
            <p className="text-gray-700 mb-6">
              Drawing from his two decades of entrepreneurial experience—including multiple 
              seven-figure partnership disasters and successful exits—Cody McLain delivers 
              the ultimate guidebook for navigating the treacherous waters of business relationships.
            </p>
            <p className="text-gray-700 mb-8">
              Whether you're considering your first co-founder, bringing on a key partner, 
              or recovering from a partnership gone wrong, this book provides the frameworks, 
              strategies, and real-world tactics you need to protect yourself while building 
              successful relationships.
            </p>
          </div>
          
          <div className="md:w-1/2 bg-gray-50 rounded-2xl p-8">
            <h3 className="text-2xl font-medium mb-6">What You'll Learn</h3>
            
            <div className="space-y-5">
              <div className="flex items-start">
                <Check className="h-5 w-5 text-green-500 mr-3 mt-1 flex-shrink-0" />
                <span className="text-gray-700">How to identify the five distinct types of partnership predators before they strike</span>
              </div>
              
              <div className="flex items-start">
                <Check className="h-5 w-5 text-green-500 mr-3 mt-1 flex-shrink-0" />
                <span className="text-gray-700">Why even the smartest entrepreneurs fall victim to sophisticated manipulation</span>
              </div>
              
              <div className="flex items-start">
                <Check className="h-5 w-5 text-green-500 mr-3 mt-1 flex-shrink-0" />
                <span className="text-gray-700">Practical due diligence strategies that go beyond basic background checks</span>
              </div>
              
              <div className="flex items-start">
                <Check className="h-5 w-5 text-green-500 mr-3 mt-1 flex-shrink-0" />
                <span className="text-gray-700">How to structure partnerships that allow for growth while preventing disaster</span>
              </div>
              
              <div className="flex items-start">
                <Check className="h-5 w-5 text-green-500 mr-3 mt-1 flex-shrink-0" />
                <span className="text-gray-700">Essential legal protections every agreement needs (and why boilerplate templates fail)</span>
              </div>
              
              <div className="flex items-start">
                <Check className="h-5 w-5 text-green-500 mr-3 mt-1 flex-shrink-0" />
                <span className="text-gray-700">When to fight for a struggling partnership and when to walk away</span>
              </div>
              
              <div className="flex items-start">
                <Check className="h-5 w-5 text-green-500 mr-3 mt-1 flex-shrink-0" />
                <span className="text-gray-700">How to rebuild your business and confidence after a partnership failure</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBookSection;
