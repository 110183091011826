
import { BookOpen } from "lucide-react";

const BookLesson = () => {
  return (
    <section className="py-24 bg-gray-50">
      <div className="container mx-auto px-6">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <span className="inline-block px-4 py-1.5 mb-6 text-sm font-medium bg-blue-100 text-blue-800 rounded-full">
              My Story
            </span>
            <h2 className="text-4xl font-avenir font-bold mb-6">
              My $2-Million Lesson
            </h2>
          </div>
          
          <div className="bg-white p-8 md:p-12 rounded-2xl shadow-sm">
            <div className="flex items-center mb-8">
              <div className="bg-red-100 p-3 rounded-full mr-5">
                <BookOpen className="h-6 w-6 text-red-500" />
              </div>
              <h3 className="text-2xl font-medium">A Harsh Lesson in Trust</h3>
            </div>
            
            <div className="prose prose-lg max-w-none">
              <p>
                At nineteen, I stood at the precipice of what I believed would be my breakthrough. 
                I had moved to Birch Bay, Washington, drawn by the allure of a promising partnership 
                with a tech entrepreneur. Little did I know, my optimism was about to collide with a 
                harsh lesson in trust.
              </p>
              
              <p>
                This was my baptism by fire into the world of business partnerships, and from its flames, 
                a relentless resolve was forged. Over the next two decades, I would encounter many more 
                challenging partnerships—some with calculated fraudsters, others with well-meaning but 
                incompatible partners. Each experience added another layer to my understanding of what 
                makes partnerships succeed or fail.
              </p>
              
              <p>
                This book isn't just my story. It distills hard-won lessons from countless entrepreneurs 
                into practical guidance for those considering or currently managing a business partnership. 
                Because while the right partnership can elevate your business beyond what you could achieve 
                alone, the wrong one can destroy everything you've worked to build.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookLesson;
