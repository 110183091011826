
import { useRef, useState } from "react";

interface SubstackEmbedProps {
  substackUrl?: string;
  className?: string;
}

const SubstackEmbed = ({ substackUrl = "https://mindhack.substack.com/embed", className = "" }: SubstackEmbedProps) => {
  const [email, setEmail] = useState("");
  const iframeRef = useRef<HTMLIFrameElement>(null);
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Send the email to the iframe
    if (iframeRef.current && email) {
      // Get the iframe document
      const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow?.document;
      
      if (iframeDoc) {
        // Find the email input in the iframe
        const emailInput = iframeDoc.querySelector('input[type="email"]') as HTMLInputElement;
        if (emailInput) {
          // Set the value
          emailInput.value = email;
          
          // Find and click the submit button
          const submitButton = iframeDoc.querySelector('button[type="submit"]') as HTMLButtonElement;
          if (submitButton) {
            submitButton.click();
          }
        }
      }
    }
  };
  
  return (
    <div className={`substack-embed-wrapper ${className}`}>
      {/* Custom form for email capture */}
      <form onSubmit={handleSubmit} className="mb-4 flex flex-col sm:flex-row gap-3">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          className="flex-1 px-4 py-3 rounded-lg border border-white/20 bg-white/10 text-white focus:outline-none focus:ring-2 focus:ring-amber-400/30"
          required
        />
        <button
          type="submit"
          className="px-6 py-3 bg-amber-500 text-white rounded-lg hover:bg-amber-600 transition-colors whitespace-nowrap"
        >
          Subscribe
        </button>
      </form>
      
      {/* Hidden iframe that will be manipulated via the custom form */}
      <iframe 
        ref={iframeRef}
        src={substackUrl}
        width="0"
        height="0"
        style={{ border: "none", position: "absolute", opacity: 0 }}
        frameBorder="0"
        scrolling="no"
        title="Substack newsletter embed"
      />
      
      <p className="text-sm text-gray-400 text-center">
        No spam, unsubscribe anytime.
      </p>
    </div>
  );
};

export default SubstackEmbed;
