
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import SubstackEmbed from "@/components/SubstackEmbed";

interface SampleFormData {
  email: string;
}

const FreeSample = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();
  const { register, handleSubmit, reset, formState: { errors } } = useForm<SampleFormData>();

  const onSubmit = async (data: SampleFormData) => {
    setIsSubmitting(true);
    try {
      // Display toast
      toast({
        title: "Success!",
        description: "Check your email for the free sample chapter.",
      });
      reset();
    } catch (error) {
      console.error('Error sending sample:', error);
      toast({
        title: "Error",
        description: "Failed to send the sample. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="sample" className="py-16 bg-white">
      <div className="container mx-auto px-6">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl font-serif mb-8">Read a Free Sample</h2>
          <div className="prose prose-lg max-w-none mb-8">
            <p>
              "The sound of the alarm clock pierced through the silence of my room. It was 5 AM, and while most teenagers would be dreading the early morning wake-up call, I felt a surge of excitement. This wasn't just another day—it was the day I would launch my first business..."
            </p>
            <p className="text-lg font-medium">
              Want to read more? Get the first chapter free!
            </p>
          </div>

          <div className="max-w-md">
            <SubstackEmbed className="bg-gray-50 p-6 rounded-lg" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FreeSample;
