
import Navigation from "@/components/Navigation";
import Hero from "@/components/Hero";
import NewsletterSection from "@/components/NewsletterSection";
import Footer from "@/components/Footer";

const HomeOld = () => {
  return (
    <div className="min-h-screen bg-background">
      <Navigation />
      <Hero />
      <NewsletterSection />
      <Footer />
    </div>
  );
};

export default HomeOld;
