
import { BookOpen, Mail } from "lucide-react";
import SubstackEmbed from "./SubstackEmbed";

const NewsletterSection = () => {
  return (
    <section className="py-24 bg-[#1A1F2C] text-white">
      <div className="container mx-auto px-6">
        <div className="max-w-3xl mx-auto text-center">
          <div className="inline-flex justify-center items-center mb-6 bg-white/10 p-3 rounded-full">
            <Mail className="h-6 w-6 text-amber-400" />
          </div>
          <h2 className="text-4xl font-avenir font-bold mb-6">
            Be the First to Know
          </h2>
          <p className="text-xl text-gray-300 mb-8">
            Sign up to receive updates on the book release, exclusive content, and pre-order specials.
          </p>
          
          <div className="bg-white/5 p-8 rounded-xl mb-8">
            <SubstackEmbed />
          </div>
          
          <div className="flex items-center justify-center mb-4">
            <BookOpen className="h-5 w-5 text-amber-400 mr-2" />
            <h3 className="text-xl font-medium">Order Now</h3>
          </div>
          
          <div className="flex flex-wrap justify-center gap-4">
            <button className="px-8 py-3 bg-white text-[#1A1F2C] rounded-lg hover:bg-gray-100 transition-colors">
              Amazon
            </button>
            <button className="px-8 py-3 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors">
              Barnes & Noble
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsletterSection;
