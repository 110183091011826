
import { Award, Briefcase, FileText } from "lucide-react";

const AuthorSection = () => {
  return (
    <section className="py-24 bg-gray-50">
      <div className="container mx-auto px-6">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <span className="inline-block px-4 py-1.5 mb-6 text-sm font-medium bg-purple-100 text-purple-800 rounded-full">
              About The Author
            </span>
            <h2 className="text-4xl font-avenir font-bold mb-6">
              Cody McLain
            </h2>
          </div>
          
          <div className="flex flex-col md:flex-row gap-8 items-center">
            <div className="md:w-1/3">
              <img 
                src="/lovable-uploads/e731a013-5832-45fa-be5f-9300697e20db.png" 
                alt="Cody McLain" 
                className="rounded-xl shadow-md w-full"
              />
            </div>
            
            <div className="md:w-2/3">
              <div className="prose prose-lg max-w-none">
                <p>
                  Cody McLain has been building businesses since he was 15 years old. He's the founder of 
                  SupportNinja and WireFuse Media, and has built and sold multiple seven-figure companies. 
                  His work has been featured in Entrepreneur, Forbes, Business Insider, and Inc. Magazine.
                </p>
                
                <p>
                  After losing millions to partnership disasters and subsequently building successful 
                  ventures with the right partners, Cody has become obsessed with understanding what 
                  makes business relationships work—or fail catastrophically.
                </p>
              </div>
              
              <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="flex items-center">
                  <Award className="h-5 w-5 text-amber-500 mr-2" />
                  <span className="text-gray-700">Serial Entrepreneur</span>
                </div>
                
                <div className="flex items-center">
                  <Briefcase className="h-5 w-5 text-blue-500 mr-2" />
                  <span className="text-gray-700">Company Founder</span>
                </div>
                
                <div className="flex items-center">
                  <FileText className="h-5 w-5 text-green-500 mr-2" />
                  <span className="text-gray-700">Featured in Forbes</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuthorSection;
